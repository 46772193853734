import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import config from '../config';
import { useAlgo } from '../hooks/useAlgo';
import { useBalanceStore } from '../hooks/useBalanceStore';
import useUser from '../hooks/useUser';
import formatNumber from '../utils/formatNumber';
import FlyoutMenu from './FlyoutMenu';
import Spinner from './Spinner';
import UserMenu from './UserMenu';

const EARN_MENU = [
  {
    title: 'Shitty Treats',
    description: 'Learn everything about Shitty Treats',
    href: '/treats',
    image: '/shitty-treats.png',
  },
  {
    title: 'Adventures',
    description: 'Send your kitties on Shitty Adventures to earn Shitty Treats.',
    href: '/adventures',
    image: '/adventures/fishing-trip.png',
  },
  {
    title: 'Shitty Jobs',
    description: 'An AI-powered Shitty Job Board. Coming in 2025.',
    href: '/jobs',
    image: '',
  },
  {
    title: 'Treasure',
    description: 'Open your Shitty Treasure Boxes and claim your rewards.',
    href: '/treasure',
    image: '/boxes/golden-treasure.png',
  },
  {
    title: 'Vending Machine',
    description: 'Spend Shitty Treats for an NFT from the Shitty Vending Machine.',
    href: '/vending-machine',
    image: '/vending-machine.png',
  },
  {
    title: 'Raffles',
    description: 'Enter raffles using Shitty Treats and other community tokens.',
    href: '/raffles',
    image: '/blocks/minified/1102790446.png',
  },
  {
    title: 'Vault Lottery',
    description: 'Win $ALGO each week with the Shitty Vault Lottery.',
    href: '/vault-lottery',
    image: '',
  },
];

const CITIES_MENU = [
  {
    title: 'Shitty Cities',
    description: 'View all 500 Shitty Cities',
    href: '/cities',
    image: '/city-example.png',
  },
  {
    title: 'Shitty City Blocks',
    description: 'View all Shitty City Blocks',
    href: '/blocks',
    image: '/blocks/minified/1102791159.png',
  },
  {
    title: 'My Cities',
    description: 'View the Shitty Cities you own',
    href: '/profile?tab=cities',
    image: '',
  },
  {
    title: 'City Builder',
    description: `Make your cities even shittier by purchasing an Upgrade or adding Shitty City Blocks.`,
    href: '/city-builder',
    image: '/builder/biodome-city-client.png',
  },
  {
    title: 'Block Swap',
    description: 'Swap a Shitty City Block with another using Shitty Treats.',
    href: '/block-swap',
    image: '',
  },
  {
    title: 'Census',
    description: 'View statistics about the Shitty population.',
    href: '/city-census',
    image: '',
  },
  {
    title: 'Collab Block Staking',
    description: 'Stake your Shitty Cities, Shitty Kitties, and Collab NFTs to claim Shitty City Collab Blocks.',
    href: '/collab-block-staking',
    image: '/blocks/minified/1158780700.png',
  },
];

const KITTIES_MENU = [
  {
    title: 'First Litter',
    description: 'View all 470 Shitty Kitties from the First Litter',
    href: '/first-litter',
    image: '/first-litter-collage.png',
  },
  {
    title: 'Second Litter',
    description: 'View all 500 Shitty Kitties from the Second Litter',
    href: '/second-litter',
    image: '/second-litter-collage.png',
  },
  {
    title: 'Third Litter',
    description: 'View all 1,069 Shitty Kitties from the Third Litter',
    href: '/third-litter',
    image: '/third-litter-collage.png',
  },
  {
    title: 'Fourth Litter',
    description: 'View all 2,405 Shitty Kitties from the Fourth Litter',
    href: '/fourth-litter',
    image: '/twitter-share-image.png',
  },
  {
    title: 'Banner Builder',
    description: 'Create 𝕏 banners to show off your Shitty Kitties.',
    href: '/banner-builder',
    image: '/banner-2.png',
  },
  {
    title: 'Meme Generator',
    description: 'Create memes using your Shitty Kitties.',
    href: '/meme-generator',
    image: '/meme-preview.jpeg',
  },
  {
    title: 'Paintings',
    description: 'Want to purchase an 11x14 acrylic painting of one of your Shitty Kitties?',
    href: '/paintings',
    image: '/harry-potter-painting.png',
  },
  {
    title: 'Trait Swapper',
    description: 'Swap traits between two of your Fourth Litter Shitty Kitties.',
    href: '/trait-swapper',
    image: '/trait-swap.png',
  },
  // {
  //   title: 'Trait Shuffle',
  //   description: 'Shuffle new traits for your Fourth Litter Shitty Kitties.',
  //   href: '/trait-shuffle',
  //   image: '/trait-shuffle.png',
  // },
].filter((menu) => !menu.disabled);

const UTILITIES_MENU = [
  {
    title: 'Mass Add',
    description: 'Opt-in to any number of Algorand ASAs at once.',
    href: '/mass-add',
  },
  {
    title: 'Mass Send',
    description: 'Send any number of Algorand ASAs at once.',
    href: '/mass-send',
  },
  {
    title: 'Snapshot',
    description: 'See the list of holders for a given Algorand NFT collection.',
    href: '/snapshot',
  },
];

function DropdownLink(props) {
  const router = useRouter();
  let { href, children, ...rest } = props;

  return (
    <Link href={href}>
      <a
        {...rest}
        className={clsx(
          router.pathname == href ? 'text-primary-500' : '',
          'block px-4 py-2 text-lg font-bold hover:text-primary-500 transition-colors duration-200'
        )}
      >
        {children}
      </a>
    </Link>
  );
}

function DrawerLink({ href, children, subheading, disabled }) {
  const router = useRouter();

  return (
    <Link href={href}>
      <a
        className={`${router.pathname === href ? 'text-primary-500' : 'text-neutral-200'} ${
          disabled && 'pointer-events-none opacity-30'
        } group text-xl font-bold transition-all duration-200 hover:text-primary-500 flex flex-col space-y-1`}
      >
        <span>{children}</span>
        {subheading && (
          <span className='text-sm font-normal leading-normal transition-opacity duration-200 text-neutral-500 group-hover:opacity-50'>
            {subheading}
          </span>
        )}
      </a>
    </Link>
  );
}

function Header() {
  const router = useRouter();
  const { user } = useUser();
  const { address, withdrawFromVault } = useAlgo();
  const { walletBalance, vaultBalance } = useBalanceStore();
  const [isOpen, setIsOpen] = useState(false);
  const [withdrawLoading, setWithdrawLoading] = useState(false);

  const onWithdraw = async () => {
    if (withdrawLoading) return;

    try {
      setWithdrawLoading(true);
      await withdrawFromVault();
      toast.success('Withdrawal successful');
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data || 'Unexpected error');
    } finally {
      setWithdrawLoading(false);
    }
  };

  // on route change, close menu
  useEffect(() => {
    setIsOpen(false);
  }, [router.pathname]);

  return (
    <>
      {/* <div className='flex items-center gap-3 py-2 px-4 lg:px-8'>
        <p className='font-medium'>Websites</p>
        <ul className='flex items-center gap-3'>
          <li>
            <Link href='/'>
              <a className='ring-1 ring-primary-500 block rounded-md p-2'>
                <img src='/shitty-kitties-logo.png' alt={`${config.siteName} logo`} className='h-5' />
                <span className='sr-only'>Go to shitty kitties website</span>
              </a>
            </Link>
          </li>
          <li>
            <Link href='https://www.shittycities.art'>
              <a className='block rounded-md p-2'>
                <img src='/shitty-cities-logo.png' alt={`shitty cities logo`} className='h-5' />
                <span className='sr-only'>Go to shitty cities website</span>
              </a>
            </Link>
          </li>
        </ul>
      </div> */}
      <div className='relative !z-[999] pt-4 pb-4 px-4 lg:px-8 bg-neutral-900'>
        <div className='flex items-center justify-between w-full'>
          <div className='flex-shrink-0 flex space-x-10 items-center'>
            <Link href='/'>
              <a className='flex'>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img src='/sk-logo-colored.png' alt={`${config.siteName} logo`} className='h-8' />
                <h1 className='sr-only'>{config.siteName} - An Algorand NFT Collection</h1>
              </a>
            </Link>

            <nav className='items-center hidden xl:flex'>
              <ul className='flex items-center space-x-8'>
                <li>
                  <Link href='/marketplace'>
                    <a
                      className={`${
                        router.pathname == '/marketplace' ? 'text-primary-500' : ''
                      } font-bold transition-colors duration-200 hover:text-primary-500 inline-flex items-center text-xl`}
                    >
                      Marketplace
                    </a>
                  </Link>
                </li>
                {/* <li>
                  <FlyoutMenu title='Collections' links={COLLECTIONS_MENU} />
                </li> */}
                <li>
                  <FlyoutMenu title='Kitties' links={KITTIES_MENU} />
                </li>
                <li>
                  <FlyoutMenu title='Cities' links={CITIES_MENU} />
                </li>
                <li>
                  <FlyoutMenu title='Earn' links={EARN_MENU} />
                </li>
                <li>
                  <FlyoutMenu title='Utilities' links={UTILITIES_MENU} />
                </li>
                {user && (
                  <li>
                    <Link href='/admin'>
                      <a
                        className={`${
                          router.pathname == '/admin' ? 'text-primary-500' : ''
                        } font-bold transition-colors duration-200 hover:text-primary-500 inline-flex items-center text-xl`}
                      >
                        Admin
                      </a>
                    </Link>
                  </li>
                )}
              </ul>
            </nav>
          </div>

          <div className='flex items-center justify-end space-x-10'>
            <ul className='flex items-center justify-center space-x-6'>
              <li className='hidden md:block'>
                <a href={config.discord} className='group' target={'_blank'} rel='noreferrer'>
                  <div className='w-[32px]'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      viewBox='0 0 640 512'
                      className='text-white transition-colors duration-200'
                      fill='currentColor'
                    >
                      <path d='M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z' />
                    </svg>
                  </div>
                  <span className='sr-only'>Join {config.collectionName} Discord</span>
                </a>
              </li>
              <li className='hidden md:block'>
                <a href={config.twitter} className='group' target={'_blank'} rel='noreferrer'>
                  <div className='w-[22px]'>
                    <svg
                      viewBox='0 0 1200 1227'
                      xmlns='http://www.w3.org/2000/svg'
                      className='text-white transition-colors duration-200'
                      fill='currentColor'
                    >
                      <path
                        d='M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z'
                        fill='currentColor'
                      ></path>
                    </svg>
                  </div>
                  <span className='sr-only'>{config.collectionName} 𝕏</span>
                </a>
              </li>

              <li>
                <Link href='/profile/search'>
                  <a className='group'>
                    <div className='w-[26px]'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 512 512'
                        className='text-white transition-colors duration-200'
                        fill='currentColor'
                      >
                        <path d='M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z' />
                      </svg>
                    </div>
                    <span className='sr-only'>Search Shitty Profiles</span>
                  </a>
                </Link>
              </li>

              <UserMenu />

              <li className='flex items-center text-white xl:hidden'>
                <button type='button' onClick={() => setIsOpen(true)}>
                  <div className='w-[32px] flex justify-center items-center'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' fill='currentColor' className='h-[32px]'>
                      <path d='M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z' />
                    </svg>
                  </div>
                  <span className='sr-only'>Toggle menu</span>
                </button>
              </li>
            </ul>
          </div>

          <Transition show={isOpen} className={`fixed inset-0 z-[9999] flex ${!isOpen && 'pointer-events-none'}`}>
            {/* Off-canvas menu overlay, show/hide based on off-canvas menu state. */}
            <Transition.Child
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              {(ref) => (
                <div ref={ref} className='absolute inset-0 z-[9999]'>
                  <div onClick={() => setIsOpen(false)} className='absolute inset-0 bg-black opacity-75' />
                </div>
              )}
            </Transition.Child>

            {/* Off-canvas menu, show/hide based on off-canvas menu state. */}
            <Transition.Child
              enter='transition ease-in-out duration-300 transform'
              enterFrom='translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='translate-x-full'
              className='fixed right-0 flex flex-col z-[9999] flex-1 w-full h-full max-w-xs pt-5 pb-4 md:max-w-2xl bg-neutral-900'
            >
              <div className='absolute top-0 left-0 p-1 -ml-14'>
                <Transition.Child
                  enter='transition-opacity duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='transition-opacity duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                  className='flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-neutral-600'
                  aria-label='Close sidebar'
                  as='button'
                  onClick={() => setIsOpen(false)}
                >
                  <div className='text-white'>
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' fill='currentColor' className='w-[20px]'>
                      <path d='M312.1 375c9.369 9.369 9.369 24.57 0 33.94s-24.57 9.369-33.94 0L160 289.9l-119 119c-9.369 9.369-24.57 9.369-33.94 0s-9.369-24.57 0-33.94L126.1 256L7.027 136.1c-9.369-9.369-9.369-24.57 0-33.94s24.57-9.369 33.94 0L160 222.1l119-119c9.369-9.369 24.57-9.369 33.94 0s9.369 24.57 0 33.94L193.9 256L312.1 375z' />
                    </svg>
                  </div>
                </Transition.Child>
              </div>
              <nav className='px-10 pt-20 pb-40 space-y-16 overflow-scroll'>
                <div>
                  <div className='flex justify-end gap-6 mb-10 -mt-16'>
                    <div>
                      <a href={config.discord} className='group' target={'_blank'} rel='noreferrer'>
                        <div className='w-[32px]'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            viewBox='0 0 640 512'
                            className='text-white transition-colors duration-200'
                            fill='currentColor'
                          >
                            <path d='M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z' />
                          </svg>
                        </div>
                        <span className='sr-only'>Join {config.collectionName} Discord</span>
                      </a>
                    </div>
                    <div className='flex items-center'>
                      <a href={config.twitter} className='group' target={'_blank'} rel='noreferrer'>
                        <div className='w-[22px]'>
                          <svg
                            viewBox='0 0 1200 1227'
                            xmlns='http://www.w3.org/2000/svg'
                            className='text-white transition-colors duration-200'
                            fill='currentColor'
                          >
                            <path
                              d='M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z'
                              fill='currentColor'
                            ></path>
                          </svg>
                        </div>
                        <span className='sr-only'>{config.collectionName} 𝕏</span>
                      </a>
                    </div>
                  </div>
                </div>

                <div>
                  <h2 className='mb-6 text-base font-bold text-neutral-600 -mt-6'>Kitties</h2>

                  <ul className='grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-12'>
                    <li>
                      <DrawerLink href='/marketplace' subheading='See all Shitty NFTs that are for sale on the secondary market.'>
                        Marketplace
                      </DrawerLink>
                    </li>
                    {KITTIES_MENU.map((link, index) => (
                      <li key={index}>
                        <DrawerLink href={link.href} subheading={link.description}>
                          {link.title}
                        </DrawerLink>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h2 className='mb-6 text-base font-bold text-neutral-600 -mt-6'>Cities</h2>

                  <ul className='grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-12'>
                    {CITIES_MENU.map((link, index) => (
                      <li key={index}>
                        <DrawerLink href={link.href} subheading={link.description}>
                          {link.title}
                        </DrawerLink>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h2 className='mb-6 text-base font-bold text-neutral-600 -mt-6'>Earn</h2>

                  <ul className='grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-12'>
                    {EARN_MENU.map((link, index) => (
                      <li key={index}>
                        <DrawerLink href={link.href} subheading={link.description}>
                          {link.title}
                        </DrawerLink>
                      </li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h2 className='mb-6 text-base font-bold text-neutral-600'>Utilities</h2>

                  <ul className='grid grid-cols-1 md:grid-cols-2 gap-y-8 gap-x-12'>
                    {UTILITIES_MENU.map((link, index) => (
                      <li key={index}>
                        <DrawerLink href={link.href} subheading={link.description}>
                          {link.title}
                        </DrawerLink>
                      </li>
                    ))}
                    <li>
                      <DrawerLink href={config.docs} subheading='Shitty Docs is a repository of all things Shitty Kitty'>
                        Shitty Docs
                      </DrawerLink>
                    </li>
                    <li>
                      <DrawerLink
                        href={'https://www.howtoalgonft.com'}
                        subheading='Guides on how to create, buy, and sell NFTs on the Algorand blockchain'
                      >
                        New to Algorand?
                      </DrawerLink>
                    </li>
                    <li>
                      <DrawerLink href={'/privacy-policy'}>Privacy Policy</DrawerLink>
                    </li>
                    <li>
                      <DrawerLink href={'/terms-and-conditions'}>Terms & Conditions</DrawerLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </Transition.Child>
            <div className='flex-shrink-0 w-14'>{/* Dummy element to force sidebar to shrink to fit close icon */}</div>
          </Transition>
        </div>
      </div>

      {address && typeof walletBalance === 'number' && (
        <div className='flex md:justify-end flex-col md:flex-row px-5 py-4 bg-neutral-900 md:gap-5 gap-2'>
          <p className='text-gray-400'>
            Wallet: <span className='font-bold text-white'>{formatNumber(walletBalance)} Treats</span>
          </p>
          {typeof vaultBalance === 'number' && (
            <div className='flex items-center gap-2'>
              <p className='text-gray-400'>
                Vault: <span className='font-bold text-white'>{formatNumber(vaultBalance)} Treats</span>
              </p>
              <button
                type='button'
                disabled={withdrawLoading}
                onClick={onWithdraw}
                className={clsx(
                  {
                    'opacity-50 cursor-not-allowed': withdrawLoading,
                  },
                  'w-[100px] h-[30px] text-sm font-bold border-gray-500 border rounded-md text-white hover:bg-primary-600 transition-colors duration-200',
                  { 'hover:bg-primary-600': !withdrawLoading }
                )}
              >
                {withdrawLoading ? <Spinner dimensionClasses='w-4 h-4' /> : 'Withdraw'}
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Header;
